body {
  padding: 500;
}
#contactForm {
  padding-top: 1%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 1%;
}
#gallery {
  padding-left: 1%;
  padding-right: 1%;
}
.title {
  text-align: center;
  padding: 10px;
  font-weight: bold;
}
.description {
  text-align: center;
  padding: 10px;
  font-size: 18px;
}
#workTruckIMG {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}
#main-container {
  background-color: #F5F5F5;
}
#background {
  background-color: #434343 ;
  padding: 18px;
}
.links {
  text-align: center;
  padding-bottom: 10px;
}
.link {
  color: white;
  font-weight: bold;
  padding: 5%;
  text-decoration: none;
}
.button {
  background-color: green;
  padding: 2px;
  border: 2px solid black;
  display: inline;
}
.link:hover {
  color: blue;
}

footer {
  border-top: 5px solid black;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  text-align: center;
}
.footIMG {
  width: 20px;
  height: 20px;
  padding-top: 2px;
}
.footEmail {
  text-decoration: none;
  color: black
}
.middleIMG {
  padding: 10px;
  height: 300px;
}
#titleSecondary {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  font-weight: bold;
  text-decoration: underline;
}
#workSect {
  border-top: 2px solid black;
  padding-top: 10px;
}
ul {
  list-style: none;
}
ul li:before {
  content: '✓ ';
}

.col-sm-3 {
  padding-bottom: 10px;
}
.col-sm-6 {
  padding-bottom: 10px;
}
.col-sm-4 {
  padding-bottom: 10px;
}
#fcf-form {
  display:block;
}

.fcf-body {
  margin: 0;
  font-family: -apple-system, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  padding: 30px;
  padding-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  max-width: 100%;
}

.fcf-form-group {
  margin-bottom: 1rem;
}

.fcf-input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.fcf-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  outline: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fcf-form-control:focus {
  border: 1px solid #313131;
}

select.fcf-form-control[size], select.fcf-form-control[multiple] {
  height: auto;
}

textarea.fcf-form-control {
  font-family: -apple-system, Arial, sans-serif;
  height: auto;
}

label.fcf-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.fcf-credit {
  padding-top: 10px;
  font-size: 0.9rem;
  color: #545b62;
}

.fcf-credit a {
  color: #545b62;
  text-decoration: underline;
}

.fcf-credit a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.fcf-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .fcf-btn {
      transition: none;
  }
}

.fcf-btn:hover {
  color: #212529;
  text-decoration: none;
}

.fcf-btn:focus, .fcf-btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.fcf-btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.fcf-btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.fcf-btn-primary:focus, .fcf-btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.fcf-btn-lg, .fcf-btn-group-lg>.fcf-btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.fcf-btn-block {
  display: block;
  width: 100%;
}

.fcf-btn-block+.fcf-btn-block {
  margin-top: 0.5rem;
}
.fcf-h1 {
  font-weight: bold;
  padding-bottom: 10px;
  text-align: center;
}
input[type="submit"].fcf-btn-block, input[type="reset"].fcf-btn-block, input[type="button"].fcf-btn-block {
  width: 100%;
}